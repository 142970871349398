import Tracker, { TrackerWithAutoTracking } from 'analytics/OmniTracker/Tracker'
import { PlainObject } from 'shared/util/types.util'
import { pick, omitBy, isNil } from 'lodash'
import { componentNameFrom, TrackingComponent } from 'analytics/OmniTracker/TrackingComponent'
import BaseTrackingIdProvider, { TrackingIdProvider } from 'analytics/OmniTracker/TrackingIdProvider'
import { filterMap } from 'shared/util/ArrayUtils'

export enum AppName {
  CALENDAR = 'Calendar',
  OPERATOR = 'Operator',
  CONTRIBUTOR = 'Contributor',
  REPORTING = 'Reporting',
  CHECK_IN = 'Check-In',
  GENERAL_BOOKING_FLOW = 'ExternalBooking_General',
  ORDER_MANAGEMENT = 'OrderManagement',
  PARTNER_MANAGEMENT = 'Partner_Management',
  COMPANY_USER_MANAGEMENT = 'Company_User_Management',
  SETTINGS = 'Settings',
  SITE_PROFILE = 'Site_Profile',
  EMPTIES = 'Empties',
}

export interface TrackingEvent {
  /**
   * An UpperCamelCase name of the object involved in the event. Technically, this refers to models or DTOs.
   *
   * E.g. `AvailableSlot`, `Advice` or `Slot`
   */
  object?: string

  /**
   * The action to record. This can either be a verb describing the action (e.g. `create`, `update`, `cancel`)
   * or the result of an action (e.g. `not found` or `success`)
   */
  action?: string

  /**
   * The react component emitting the event. UpperCamelCase name.
   */
  component?: TrackingComponent

  /**
   * The app (i.e. entry point) emitting the event.
   */
  app?: AppName

  /**
   * Whether the event was triggered automatically (via generic click tracking)
   * vs if it was generated manually.
   */
  automated?: boolean

  /**
   * Any custom data associated with the event.
   */
  payload?: PlainObject
}

export type FixedAppTrackingEvent = Omit<TrackingEvent, 'app'>

interface BaseTracker {
  /**
   * Terminates the previous session and starts a new one.
   */
  resetSession(): void
}

export interface FixedAppTracker extends TrackingIdProvider, BaseTracker {
  /**
   * Creates a new tracker that is associated with the given component. The `component` argument can thus
   * be omitted from method calls on this object.
   * @param component The component to set
   */
  forComponent(component: TrackingComponent): FixedComponentTracker

  trackEvent(event: FixedAppTrackingEvent): void
}

export type FixedComponentTrackingEvent = Omit<FixedAppTrackingEvent, 'component'>

/**
 * A tracker that is tied to both a app and a component name and is thus easier to use
 */
export interface FixedComponentTracker extends BaseTracker {
  readonly componentName: string

  /**
   * Shorthand for tracking an event.
   * @param object The object name involved in the event. See {@link TrackingEvent.object}
   * @param action The action to be tracked. See {@link TrackingEvent.action}
   * @param payload Optional custom data to be associated with the event. See {@link TrackingEvent.payload}
   */
  track(object: string, action: string, payload?: object): void

  trackEvent(event: FixedComponentTrackingEvent): void

  /**
   * Same as {@link OmniTracker.inputId}, but without the need to specify the component explicitly.
   * @param fieldName The name of the field to generate the ID of
   */
  inputId(fieldName: string): string

  /**
   * Same as {@link OmniTracker.actionId}, but without the need to specify the component explicitly.
   * @param action The action to be executed when the user interacts with the element
   */
  actionId(action: string): string

  /**
   * Creates a new FixedComponentTracker that appends the given component to the existing
   * components that are tracked.
   * @param component The component to add
   */
  forSubComponent(component: TrackingComponent): FixedComponentTracker
}

/**
 * Acts as an aggregate over many tracking technologies. Provides a tracking-technology-agnostic
 * interface for tracking user interactions within our app.
 */
export default class OmniTracker extends BaseTrackingIdProvider implements BaseTracker {
  private static FixedAppTracker = class extends BaseTrackingIdProvider implements FixedAppTracker {
    private readonly parent: OmniTracker

    private readonly appName: AppName

    constructor(parent: OmniTracker, appName: AppName) {
      super()
      this.parent = parent
      this.appName = appName
    }

    forComponent(component: string) {
      return this.parent.forComponent(this.appName, component)
    }

    trackEvent(event: FixedAppTrackingEvent): void {
      this.parent.trackEvent({ app: this.appName, ...event })
    }

    resetSession(): void {
      this.parent.resetSession()
    }
  }

  private static FixedComponentTracker = class implements FixedComponentTracker {
    private readonly parent: OmniTracker

    private readonly appName: AppName

    readonly componentName: string

    constructor(parent: OmniTracker, appName: AppName, component: TrackingComponent) {
      this.parent = parent
      this.appName = appName
      this.componentName = componentNameFrom(component)
    }

    track(object: string, action: string, payload?: PlainObject): void {
      this.trackEvent({ object, action, payload })
    }

    trackEvent(event: FixedComponentTrackingEvent): void {
      this.parent.trackEvent({ app: this.appName, component: this.componentName, ...event })
    }

    resetSession(): void {
      this.parent.resetSession()
    }

    inputId(fieldName: string): string {
      return this.parent.inputId(this.componentName, fieldName)
    }

    actionId(action: string): string {
      return this.parent.actionId(this.componentName, action)
    }

    forSubComponent(component: TrackingComponent): FixedComponentTracker {
      return this.parent.forComponent(this.appName, `${this.componentName}_${component}`)
    }
  }

  private trackers: Tracker[] = []

  private readonly autoTrackers: TrackerWithAutoTracking[] = []

  private globalContext: PlainObject = {}

  private globalContextStack: PlainObject[] = []

  constructor(trackers: Tracker[] = []) {
    super()

    this.trackers = trackers
    this.autoTrackers = filterMap(
      trackers,
      (tracker) => (OmniTracker.isAutoTracker(tracker) ? tracker : null),
    )
  }

  private static eventName(object?: string, action?: string) {
    return [object, action].filter((s) => s).join(' ')
  }

  private static isAutoTracker(tracker: Tracker): tracker is TrackerWithAutoTracking {
    return 'setAutoTrackingProperties' in tracker
  }

  addTracker(tracker: Tracker): void {
    this.trackers.push(tracker)
    if (OmniTracker.isAutoTracker(tracker)) {
      this.autoTrackers.push(tracker)
    }
  }

  /**
   * Returns a tracker that is pinned to the given app. The app doesn't need to be specified again
   * for any of it's methods - i.e. the `app` parameter can be omitted on this object.
   * @param app The app name to pin the returned tracker to.
   */
  forApp(app: AppName): FixedAppTracker {
    return new OmniTracker.FixedAppTracker(this, app)
  }

  /**
   * Returns a tracker that is pinned to the given app and component. Neither of these parameters
   * need to be specified again for any of it's methods - i.e. the `app` and `component` parameter
   * can be omitted on this object.
   * @param app The app name to pin the returned tracker to.
   * @param component The component to pin the returned tracker to.
   */
  forComponent(app: AppName, component: TrackingComponent): FixedComponentTracker {
    return new OmniTracker.FixedComponentTracker(this, app, component)
  }

  /**
   * Tracks the given event using all active tracking systems
   * @param event The event to track
   */
  trackEvent(event: TrackingEvent): void {
    try {
      const name = OmniTracker.eventName(event.object, event.action)
      const payload = {
        ...this.globalContext,
        ...pick(event, 'component', 'app'),
        ...event.payload,
      }

      this.forEachTracker((tracker) => {
        // Auto-trackers capture their own events, thus we don't forward our automatic events to them.
        if (!(event.automated && OmniTracker.isAutoTracker(tracker))) {
          tracker.trackEvent(name, omitBy(payload, isNil))
        }
      })
    } catch (e) {
      // Just in case there are any errors... don't crash the app, tracking is always optional.

      console.warn('Error while tracking event in OmniTracker', e)
    }
  }

  resetSession(): void {
    this.forEachTracker((tracker) => tracker.resetSession())
  }

  addGlobalContext(context: PlainObject): void {
    this.globalContextStack.push(context)
    this.updateGlobalContext()
    this.forEachTracker(
      (tracker) => tracker.setAutoTrackingProperties(this.globalContext),
      this.autoTrackers,
    )
  }

  removeGlobalContext(context: PlainObject): void {
    const existingIndex = this.globalContextStack.indexOf(context)
    if (existingIndex === -1) {
      console.warn('Trying to remove global context object that does not exist', context)
      return
    }

    this.globalContextStack.splice(existingIndex, 1)
    this.updateGlobalContext()
    this.forEachTracker(
      (tracker) => tracker.setAutoTrackingProperties(this.globalContext),
      this.autoTrackers,
    )
  }

  private updateGlobalContext() {
    this.globalContext = this.globalContextStack.reduce((acc, context) => ({ ...acc, ...context }), {})
  }

  private forEachTracker<T extends Tracker>(
    callback: (tracker: T) => void, trackers: T[] = this.trackers as T[],
  ) {
    trackers.forEach((tracker) => {
      try {
        callback(tracker)
      } catch (e) {
        // Catch any errors that happen while tracking. We don't want to interrupt the user's
        // session just because our tracking might not work.

        console.warn(`Failed to run callback on tracker ${tracker.constructor.name}`, e)
      }
    })
  }
}
